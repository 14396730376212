let agencias = [
    {
        id: 396,
        name: "20 de Setembro",
        cooperativa: 5,
    },
    {
        id: 437,
        name: "Ana Rech",
        cooperativa: 5,
    },
    {
        id: 434,
        name: "Cruzeiro",
        cooperativa: 5,
    },
    {
        id: 451,
        name: "Desvio Rizzo",
        cooperativa: 5,
    },
    {
        id: 4435,
        name: "Forqueta",
        cooperativa: 5,
    },
    {
        id: 4429,
        name: "Galópolis",
        cooperativa: 5,
    },
    {
        id: 439,
        name: "Lourdes",
        cooperativa: 5,
    },
    {
        id: 150,
        name: "Pio X",
        cooperativa: 5,
    },
    {
        id: 405,
        name: "Rio Branco",
        cooperativa: 5,
    },
    {
        id: 444,
        name: "Lúcia do Piai",
        cooperativa: 5,
    },
    {
        id: 4404,
        name: "Santa Lúcia",
        cooperativa: 5,
    },
    {
        id: 4399,
        name: "Sanvitto",
        cooperativa: 5,
    },
    {
        id: 446,
        name: "Vila Cristina",
        cooperativa: 5,
    },
];

let agenciasB = [
    {
        id: 3136,
        name: "Porto Alegre",
        cooperativa: 7,
    },
];

let agenciasC = [
    {
        id: 4411,
        name: "Caxias do Sul",
        cooperativa: 12,
    },
];


let Cidades = [
    {
        "Id": "1",
        "Cidade": "Agudo"
    },
    {
        "Id": "2",
        "Cidade": "Alvorada"
    },
    {
        "Id": "3",
        "Cidade": "Arambaré"
    },
    {
        "Id": "4",
        "Cidade": "Arroio do Meio"
    },
    {
        "Id": "5",
        "Cidade": "Arroio do Tigre"
    },
    {
        "Id": "6",
        "Cidade": "Barra do Rio Azul"
    },
    {
        "Id": "7",
        "Cidade": "Bento Gonçalves"
    },
    {
        "Id": "8",
        "Cidade": "Bom Princípio"
    },
    {
        "Id": "9",
        "Cidade": "Bom Retiro do Sul"
    },
    {
        "Id": "10",
        "Cidade": "Cachoeira do Sul"
    },
    {
        "Id": "11",
        "Cidade": "Cachoeirinha"
    },
    {
        "Id": "12",
        "Cidade": "Campo Bom"
    },
    {
        "Id": "13",
        "Cidade": "Candelária"
    },
    {
        "Id": "14",
        "Cidade": "Canoas"
    },
    {
        "Id": "15",
        "Cidade": "Canudos do Vale"
    },
    {
        "Id": "16",
        "Cidade": "Caxias do Sul"
    },
    {
        "Id": "17",
        "Cidade": "Cerro Branco"
    },
    {
        "Id": "18",
        "Cidade": "Charqueadas"
    },
    {
        "Id": "19",
        "Cidade": "Colinas"
    },
    {
        "Id": "20",
        "Cidade": "Coqueiro Baixo"
    },
    {
        "Id": "21",
        "Cidade": "Cotiporã"
    },
    {
        "Id": "22",
        "Cidade": "Cruzeiro do Sul"
    },
    {
        "Id": "23",
        "Cidade": "Dona Francisca"
    },
    {
        "Id": "24",
        "Cidade": "Doutor Ricardo"
    },
    {
        "Id": "25",
        "Cidade": "Eldorado do Sul"
    },
    {
        "Id": "26",
        "Cidade": "Encantado"
    },
    {
        "Id": "27",
        "Cidade": "Esteio"
    },
    {
        "Id": "28",
        "Cidade": "Estrela"
    },
    {
        "Id": "29",
        "Cidade": "Faxinal do Soturno"
    },
    {
        "Id": "30",
        "Cidade": "Feliz"
    },
    {
        "Id": "31",
        "Cidade": "Fontoura Xavier"
    },
    {
        "Id": "32",
        "Cidade": "Forquetinha"
    },
    {
        "Id": "33",
        "Cidade": "General Câmara"
    },
    {
        "Id": "34",
        "Cidade": "Gramado"
    },
    {
        "Id": "35",
        "Cidade": "Guaíba"
    },
    {
        "Id": "36",
        "Cidade": "Guaporé"
    },
    {
        "Id": "37",
        "Cidade": "Ibarama"
    },
    {
        "Id": "38",
        "Cidade": "Igrejinha"
    },
    {
        "Id": "39",
        "Cidade": "Imigrante"
    },
    {
        "Id": "40",
        "Cidade": "Ivorá"
    },
    {
        "Id": "41",
        "Cidade": "Jaguari"
    },
    {
        "Id": "42",
        "Cidade": "Lajeado"
    },
    {
        "Id": "43",
        "Cidade": "Maquiné"
    },
    {
        "Id": "44",
        "Cidade": "Marques de Souza"
    },
    {
        "Id": "45",
        "Cidade": "Montenegro"
    },
    {
        "Id": "46",
        "Cidade": "Muçum"
    },
    {
        "Id": "47",
        "Cidade": "Nova Palma"
    },
    {
        "Id": "48",
        "Cidade": "Nova Santa Rita"
    },
    {
        "Id": "49",
        "Cidade": "Novo Hamburgo"
    },
    {
        "Id": "50",
        "Cidade": "Paraíso do Sul"
    },
    {
        "Id": "51",
        "Cidade": "Pareci Novo"
    },
    {
        "Id": "52",
        "Cidade": "Parobé"
    },
    {
        "Id": "53",
        "Cidade": "Passa Sete"
    },
    {
        "Id": "54",
        "Cidade": "Passo do Sobrado"
    },
    {
        "Id": "55",
        "Cidade": "Pelotas"
    },
    {
        "Id": "56",
        "Cidade": "Pinhal Grande"
    },
    {
        "Id": "57",
        "Cidade": "Ponte Preta"
    },
    {
        "Id": "58",
        "Cidade": "Porto Alegre"
    },
    {
        "Id": "59",
        "Cidade": "Pouso Novo"
    },
    {
        "Id": "60",
        "Cidade": "Putinga"
    },
    {
        "Id": "61",
        "Cidade": "Relvado"
    },
    {
        "Id": "62",
        "Cidade": "Restinga Seca"
    },
    {
        "Id": "63",
        "Cidade": "Rio Grande"
    },
    {
        "Id": "64",
        "Cidade": "Rio Pardo"
    },
    {
        "Id": "65",
        "Cidade": "Roca Sales"
    },
    {
        "Id": "66",
        "Cidade": "Rolante"
    },
    {
        "Id": "67",
        "Cidade": "Santa Cruz do Sul"
    },
    {
        "Id": "68",
        "Cidade": "Santa Maria"
    },
    {
        "Id": "69",
        "Cidade": "Santa Tereza"
    },
    {
        "Id": "70",
        "Cidade": "São Jerônimo"
    },
    {
        "Id": "71",
        "Cidade": "São João do Polêsine"
    },
    {
        "Id": "72",
        "Cidade": "São José do Herval"
    },
    {
        "Id": "73",
        "Cidade": "São José do Norte"
    },
    {
        "Id": "74",
        "Cidade": "São Leopoldo"
    },
    {
        "Id": "75",
        "Cidade": "São Lourenço do Sul"
    },
    {
        "Id": "76",
        "Cidade": "São Martinho da Serra"
    },
    {
        "Id": "77",
        "Cidade": "São Sebastião do Caí"
    },
    {
        "Id": "78",
        "Cidade": "São Valentim do Sul"
    },
    {
        "Id": "79",
        "Cidade": "São Vendelino"
    },
    {
        "Id": "80",
        "Cidade": "Sapucaia do Sul"
    },
    {
        "Id": "81",
        "Cidade": "Segredo"
    },
    {
        "Id": "82",
        "Cidade": "Severiano de Almeida"
    },
    {
        "Id": "83",
        "Cidade": "Silveira Martins"
    },
    {
        "Id": "84",
        "Cidade": "Sinimbu"
    },
    {
        "Id": "85",
        "Cidade": "Sobradinho"
    },
    {
        "Id": "86",
        "Cidade": "Taquara"
    },
    {
        "Id": "87",
        "Cidade": "Taquari"
    },
    {
        "Id": "88",
        "Cidade": "Travesseiro"
    },
    {
        "Id": "89",
        "Cidade": "Três Coroas"
    },
    {
        "Id": "90",
        "Cidade": "Triunfo"
    },
    {
        "Id": "91",
        "Cidade": "Vale Verde"
    },
    {
        "Id": "92",
        "Cidade": "Venâncio Aires"
    },
    {
        "Id": "93",
        "Cidade": "Vera Cruz"
    },
    {
        "Id": "94",
        "Cidade": "Veranópolis"
    },
    {
        "Id": "95",
        "Cidade": "Vespasiano Corrêa"
    }
  ];

const numParcelasFugentur = ["12x", "24x", "36x", "48x", "54x", "60x"];
const numParcelasSicrediECresol = ["36x", "48x", "60x", "72x", "84x", "96x", "120x"];

export { agencias, agenciasB, agenciasC, Cidades, numParcelasFugentur, numParcelasSicrediECresol };
