import React from "react";
import img1 from '../assets/imgs/img1.png';
import novaImagem from '../assets/imgs/novaImagem.png';
import "./../assets/styles/BcxSectionA.css";


const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="BcxSectionA">
          <div className="text">
            <p>
            As linhas de crédito Badesul têm o objetivo de atender e fortalecer
            <strong> MEIs, Microempresas e Empresas de Pequeno Porte </strong> 
            do Estado para a retomada dos negócios através da parceria com o Fundo Garantidor RS Garanti.
            </p>
          </div>

          <div className="logos">
            <img src={img1}></img>
            <img src={novaImagem}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionA;
