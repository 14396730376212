import React from "react";

const BcxSectionG = () => {
  return (
    <section id="sectionG">
      <div className="container">
        <div className="BcxSectionG">
          <h2 className="title">
            <span>Dúvidas Frequentes</span> <br />
            Sobre o Programa
          </h2>

          <div className="duvida">
            <h3>
              Como funcionam as linhas de crédito Badesul?
            </h3>
            <p>
            Estão disponíveis para todas empresas com sede ou filial localizada no estado do RS.
             Atentar as condições de cada linha de crédito.
            </p>
          </div>

          <div className="duvida">
            <h3>
            Qual o valor que posso solicitar de financiamento?
            </h3>
            <p>
            O Badesul através da parceria com o Fundo Garantidor da RS Garanti pode liberar até R$ 200 mil,
            dependendo da capacidade de pagamento de cada empresa.
            </p>
          </div>

          <div className="duvida">
            <h3>O Fundo Garantidor da RS Garanti terá custo?</h3>
            <p>
            Sim, é cobrado uma tarifa de garantia de crédito. Essa e outras dúvidas podem ser esclarecidas com a RS Garanti pelo telefone (54) 3021-0400.
            </p>
          </div>

          <div className="duvida">
            <h3>
            Para ter acesso as linhas de crédito, preciso abrir conta no Badesul?
            </h3>
            <p>
            Não, o Badesul é uma agência de fomento. O crédito será creditado na conta corrente da empresa.
            </p>
          </div>
          <h4>
          Para mais informações, entre em contato com a RS Garanti através do fone (54) 3021-0400
          </h4>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionG;
