import React from "react";
import bannercalamidade from "../assets/imgs/banner/bannercalamidade.png"

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="AcxHeader">
          <div className="conteudo">
            <img className="img01" src={bannercalamidade} alt=""/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcxHeader;
