import React from "react";
import "./../assets/styles/BcxSectionB.css";
import card1 from "../assets/imgs/bloco-2-1.png";
import card2 from "../assets/imgs/bloco-2-2.png";
import card3 from "../assets/imgs/bloco-2-3.png";

const BcxSectionB = () => {
  return (
    <section id="sectionB">
      <div className="container">
        <div className="BcxSectionB">
          <h2 className="title">
            Pré-requisitos <br />
            <span>fique atento</span>
          </h2>
          <div className="cards">
            <div className="card">
              <img src={card1} alt="Icone Item 1" />
              <h3>
              Faturamento de até R$ 4,8 milhões
                <br />
                <strong>(MEI, Micro ou Pequena Empresa)</strong>
              </h3>
            </div>
            <div className="card">
              <img src={card2} alt="Icone Item 2" />
              <h3>
              Com sede e atuando no 
                <br />
                <strong>Rio Grande do Sul</strong>
              </h3>
            </div>
            <div className="card">
              <img src={card3} alt="Icone Item 3" />
              <h3>
              Fungetur/Emergencial
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionB;
