import React from "react";
import logoUm from "../assets/imgs/badesul.png";
import riograndedosul from "../assets/imgs/riograndedosul.png";
import sebrae from "../assets/imgs/sebrae.png";
import rsgaranti from "../assets/imgs/rsgaranti.png";

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="CcxFooter">
          <div className="logos">
          <a
              href="https://www.rsgaranti.org.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={rsgaranti} alt="Logo da RS Garanti" />
            </a>
            <a
              href="https://www.badesul.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={logoUm} alt="Logo da Badesul" />
            </a>
            <a
              href="https://www.rs.gov.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img
                src={riograndedosul}
                alt="Logo do DO Estado do Rio Grande do Sul"
              />
            </a>
            <a
              href="https://sebraers.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={sebrae} alt="Logo do SEBRAE" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CcxFooter;
