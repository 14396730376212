import React from "react";
import card4 from "../assets/imgs/ico-4.png";
import card5 from "../assets/imgs/ico-5.png";
import card6 from "../assets/imgs/ico-6.png";
import card7 from "../assets/imgs/ico-7.png";

const BcxSectionC = () => {
  return (
    <section id="sectionC">
      <div className="container">
        <div className="BcxSectionC">
          <h2 className="title">
            Condições e <span>taxas diferenciadas</span>
          </h2>
          <div className="cards">
            <div className="card">
              <img src={card4} alt="Icone Item 1" />
              <h3>
                <strong>
                  Taxa de Juros
                  <br />
                  Linhas:
                </strong>
                <br />
                <br />
                Fungetur: 5% a.a. + INPC<br />
                <br />
                Mulheres Empreendedoras: 5,05% a.a. + SELIC ou TLP<br />
                <br />
                Empresarial: 5,45% a.a. + SELIC ou TLP<br />
                <br />
                Startups: 4.236% a.a. + TR<br />
              </h3>
            </div>
            <div className="card">
              <img src={card5} style={{marginBottom: "20px"}} alt="Icone Item 2" />
              <h3>
                <strong>Prazo de pagamento:</strong>
                <br />
                <br/>
                Até 48 ou 60 meses de prazo total a depender da linha de crédito com até 12 meses de carência <br />
              </h3>
            </div>
            <div className="card">
              <img src={card6} style={{marginBottom: "20px"}}  alt="Icone Item 3" />
              <h3>
                <br/>
                <br/>
                Valores de até <strong>R$ 200 mil,</strong>
                <br />
                conforme o porte da empresa
              </h3>
            </div>
            <div className="card">
              <img src={card7} style={{marginBottom: "20px"}} alt="Icone Item 4" />
              <h3>
                <strong>Garantia:</strong>
                <br />
                <br />
                Aval dos sócios e carta
                <br /> de garantia da RS Garanti
              </h3>
            </div>
          </div>
          <div className="rodape">
            <p>
              *Condições mediante disponibilidade e aprovação do crédito
              (capacidade de pagamento e sem restrições).
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionC;
